<template>
  <div class="sqg-loader">
    <div v-if="errorMsg === ''" class="sqg-loader__ring">
      <div :style="`width: ${size}px; height: ${size}px;`"></div>
      <div :style="`width: ${size}px; height: ${size}px;`"></div>
      <div :style="`width: ${size}px; height: ${size}px;`"></div>
      <div :style="`width: ${size}px; height: ${size}px;`"></div>
    </div>
    <div v-if="errorMsg === '' && msg !== ''" class="sqg-loader__msg">{{ msg }}</div>
    <div v-if="errorMsg !== ''" class="sqg-loader__errorMsg"><i class="fas fa-exclamation-triangle"></i> {{ errorMsg }}</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Loader",
  props: {
    msg: { type: String, default: '' },
    errorMsg: { type: String, default: '' },
    size: { type: String, default: '64'}
  }
});
</script>

<style scoped lang="scss">
@import "../assets/scss/main";

.sqg-loader {
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__msg  {
    color: $theme_primary;
    font-size: 24px;
    font-style: italic;
    margin-top: 16px;
  }

  &__errorMsg {
    color: $theme-red;
  }

  &__ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  &__ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 8px;
    border: 8px solid;
    border-radius: 50%;
    animation: rolls 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $theme_primary transparent transparent transparent;
  }

  &__ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  &__ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  &__ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes rolls {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
